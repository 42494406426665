import { defineStore } from 'pinia';
import { User, UserState } from '@/types';

export const useUserStore = defineStore('user', {
  state: () => <UserState>({
    info: {} as User | null,
    token: '',
    loginTime: null,
    isLogin: false,
    showLogin: false
  }),
  actions: {
    setInfo (info: User) {
      this.info = info;
    },
    setToken (token: string) {
      this.token = token;
      this.loginTime = new Date();
    },
    setLogin (isLogin: boolean) {
      this.isLogin = isLogin;
    },
    removeToken () {
      this.isLogin = false;
      this.info = null;
      this.token = '';
    },
    showLoginDialog (type: string) {
      if (this.isLogin) { return true; }
      reportEvent('User_Signup_Alertshow', { signup_source: type });
      this.showLogin = false;
      nextTick(() => {
        this.showLogin = true;
      });
      return false;
    }
  },
  persist: {
    key: '__prompt_user',
    storage: process.client ? localStorage : undefined
  }
});
